import React, { useEffect, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { ratePerWeekOnCallMask } from '../../helpers/textFieldNumberMasks';
import MaskedInput from 'react-text-mask';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import formatCurrency from 'studio/components/OfferPage/helpers/formatCurrency';
import { OMITTED_SCHEDULEA_UNIONS } from 'common/utilities/constants/noScaleUnions';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 40,
    marginBottom: 40,
  },
  terms: {
    marginTop: 10,
  },
  innerCell: {
    borderTop: 'none',
  },
  cellTitle: {
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  radioContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  radioGroupCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    borderBottom: 'none',
    paddingTop: 5,
    paddingBottom: 2,
  },
  dollar: {
    width: '10%',
    fontSize: 14,
    marginTop: 7,
    color: '#696969',
    paddingLeft: 14,
    fontFamily: 'Roboto, Helvetica, Helvetica Neue, san-serif',
  },
  tableSpacing: {
    marginTop: 16,
    marginBottom: 20,
    border: '1px solid #0000FF',
  },
  topSpacing: {
    marginTop: 20,
  },
  hoursCopy: {
    marginTop: 7,
    paddingRight: 12,
    color: '#525252',
    fontSize: 16,
    fontWeight: 'normal',
  },
  errorIcon: {
    color: 'red',
    position: 'relative',
    top: 4,
    right: 8,
  },
  errorLabel: {
    color: 'red',
    fontSize: 14,
    textAlign: 'center',
  },
  warningIcon: {
    color: '#FFA800',
    position: 'relative',
    top: 4,
    right: 8,
  },
  warningLabel: {
    color: '#FFA800',
    fontSize: 14,
    textAlign: 'center',
    maxWidth: '65%',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  hoursPerDay: {
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
  },
  rightCellArea: {
    width: '30%',
    backgroundColor: '#FFFFFF',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  fieldArea: {
    width: '40%',
    backgroundColor: '#FFFFFF!important',
  },
  noTop: {
    borderTop: 'none',
  },
  inputSpacing: {
    marginLeft: '5%',
    marginRight: '10%',
  },
  radio: {
    '&$checked': {
      color: '#0000FF',
    },
  },
  checked: {
    color: '#0000FF',
    '&, & + $label': {
      color: '#0000FF !important',
      fontSize: 16,
    },
  },
  label: {
    color: '#262626',
    fontSize: 16,
  },
  greyedOutArea: {
    width: '30%',
    backgroundColor: '#F4F4F4',
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  radioContainerPayIdle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  radioGroupCellPayIdle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    borderBottom: 'none',
    paddingTop: 5,
    paddingBottom: 2,
  },
  radioBtns: {
    display: 'flex;',
    flexFlow: 'column',
  },
  percentageContainer: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-evenly',
  },
  errorUnderline: {
    width: 196,
    borderBottom: '1px solid red',
  },
}));

const StyledOfTypography = styled(Typography)({
  textAlign: 'center',
  color: '#0000FF',
  fontWeight: 'bold',
  fontSize: 17,
  marginRight: 10,
  marginTop: 30,
});

const WeeklyInputRadioRow = props => {
  const classes = useStyles();
  const {
    rowTitle,
    rowKey,
    rowKeyStudio,
    rowKeyDistant,
    rateStudio,
    rateDistant,
    isStudioDisabled,
    onChange: upstreamOnChange,
    scaleRates,
    formErrors = {},
    formData,
    isReadOnly,
    isRadioRowDisabled,
    payAtScaleDistant,
    payIdleDaysDistantAt10th,
    payIdleDaysDistantAtNegotiated,
    isCNCSignatory,
    payIdleDaysDistantAtScale,
    defaultHours: DEFAULT_HOURS,
    unionCode,
  } = props;

  const isOmittedUnions = OMITTED_SCHEDULEA_UNIONS.includes(
    formData?.union?.code || unionCode,
  );

  const formattedStudioRate = rateStudio ? formatCurrency(rateStudio) : null;
  const formattedDistantRate = rateDistant ? formatCurrency(rateDistant) : null;

  const [studioRate, setStudioRate] = useState(formattedStudioRate);
  const [distantRate, setDistantRate] = useState(formattedDistantRate);
  let payrollScaleValStudio, payrollScaleValDistant;

  const isPayHourly = rowKey === 'payHourly';
  const isPayWeekly = rowKey === 'payWeekly';
  const isPayIdle = rowKey === 'payIdle';

  if (isPayWeekly) {
    payrollScaleValStudio = parseFloat(scaleRates?.rate);
    payrollScaleValDistant = parseFloat(scaleRates?.rateDistant);
  } else if (isPayHourly) {
    payrollScaleValStudio = parseFloat(scaleRates?.rate);
    payrollScaleValDistant = parseFloat(scaleRates?.rateDistant);
  } else if (isPayIdle) {
    payrollScaleValStudio = parseFloat(scaleRates?.rate);
    payrollScaleValDistant = parseFloat(scaleRates?.rateDistant);
  }

  const isScaleStudio = payrollScaleValStudio > 0;

  const isScaleDistant = payrollScaleValDistant > 0;

  const isScaleStudioDisabled = isScaleStudio && !isPayHourly && !isPayWeekly;
  const isScaleDistantDisabled = isScaleDistant && !isPayHourly && !isPayWeekly;

  useEffect(() => {
    setStudioRate(formattedStudioRate);
    setDistantRate(formattedDistantRate);
  }, [formattedDistantRate, formattedStudioRate]);

  const CustomRadio = (
    <Radio
      classes={{
        root: classes.radio,
        checked: classes.checked,
      }}
    />
  );

  const handleAutoScaleUpdate = (value, type) => {
    if (type === 'studio') {
      const studioValue =
        payrollScaleValStudio > parseFloat(value)
          ? false
          : payrollScaleValStudio > parseFloat(value);
      upstreamOnChange({
        payAtScale: studioValue,
        payAtScaleDistant: studioValue,
        idleAtScaleDistant: studioValue,
        payIdleDaysDistantAtNegotiated: true,
      });
    } else {
      const distantValue =
        payrollScaleValDistant > parseFloat(value)
          ? false
          : payrollScaleValDistant > parseFloat(value);
      upstreamOnChange({
        payAtScaleDistant: distantValue,
        payIdleDaysDistantAtNegotiated: true,
      });
    }
    upstreamOnChange({
      isTermsOfEmploymentV2: true,
    });
  };

  const getTypedValue = (ev = {}) => {
    const { target: { value: rawValue } = {} } = ev;
    let value = rawValue;
    if (rawValue === '' || rawValue === '0') value = '';
    return value;
  };

  const onChangeStudio = ev => {
    const typedValue = getTypedValue(ev);
    let value = typedValue > 0 ? typedValue : null;
    setStudioRate(value);
    handleAutoScaleUpdate(value, 'studio');
  };

  const onChangeDistant = ev => {
    const typedValue = getTypedValue(ev);
    let value = typedValue > 0 ? typedValue : null;
    setDistantRate(value);
    handleAutoScaleUpdate(value, 'distant');
  };

  const handlePercentageChange = ev => {
    const { target: { value: rawValue } = {} } = ev;
    upstreamOnChange({
      payIdleDaysDistantAt10th: rawValue === 'no',
      payIdleDaysDistantAt12th: rawValue === 'yes',
    });
  };

  const handleScaleChange = radioKey => {
    const isStudioRadioKey = radioKey === 'studio';
    const isDistantRadioKey = radioKey === 'distant';

    const payrollScaleValue = isStudioRadioKey
      ? payrollScaleValStudio
      : payrollScaleValDistant;
    const isNegotiatedInputStudio =
      studioRate > payrollScaleValStudio || studioRate < payrollScaleValStudio;
    const isNegotiatedInputDistant =
      distantRate > payrollScaleValDistant ||
      distantRate < payrollScaleValDistant;

    if (isNegotiatedInputStudio && isStudioRadioKey && isPayHourly) {
      upstreamOnChange({
        [rowKeyStudio]: payrollScaleValue,
      });
    }

    if (isNegotiatedInputDistant && isDistantRadioKey && isPayHourly) {
      upstreamOnChange({
        [rowKeyDistant]: payrollScaleValue,
      });
    }
  };

  const handlePayIdleChange = ev => {
    const { target: { value: rawValue } = {} } = ev;
    upstreamOnChange({
      payIdleDaysDistantAtNegotiated: rawValue === 'no',
      payIdleDaysDistantAtScale: rawValue === 'yes',
    });
  };

  const onBlurStudio = ev => {
    let blurValue;
    const typedValue = formatCurrency(studioRate);
    if (
      !scaleRates?.guaranteedHours ||
      scaleRates?.guaranteedHours === 0 ||
      isOmittedUnions
    ) {
      upstreamOnChange({
        guaranteedHours: DEFAULT_HOURS,
      });
    }
    if (
      !scaleRates?.guaranteedHoursDistant ||
      scaleRates?.guaranteedHoursDistant === 0 ||
      isOmittedUnions
    ) {
      upstreamOnChange({
        guaranteedHoursDistant: DEFAULT_HOURS,
      });
    }
    // when studio rate decimal values are changed, distant rate is not changing accordingly
    // commented for future reference
    // if (parseInt(studioRate, 10) === parseInt(payrollScaleValStudio, 10))
    //   return;
    if (studioRate) {
      blurValue = typedValue;
      upstreamOnChange({
        [rowKeyStudio]: blurValue,
        [rowKeyDistant]: blurValue,
        payIdleDaysDistantAtScale: false,
        payIdleDaysDistantAtNegotiated: true,
        payAtScaleDistant: false,
      });
      handleAutoScaleUpdate(blurValue, 'distant');
    }
    if ((!studioRate && distantRate) || (!typedValue && distantRate)) {
      blurValue = distantRate;
      // copy to studio
      upstreamOnChange({
        [rowKeyStudio]: blurValue,
      });
      handleAutoScaleUpdate(blurValue, 'studio');
    }

    if (studioRate && !distantRate) {
      blurValue = typedValue;
      // copy to distant
      upstreamOnChange({
        [rowKeyDistant]: blurValue,
        payAtScaleDistant: true,
      });
      handleAutoScaleUpdate(blurValue, 'distant');
    }
    setStudioRate(blurValue);
    upstreamOnChange({
      tableErrors: {
        isRateBelowScale: blurValue < payrollScaleValStudio && isCNCSignatory,
      },
    });
  };

  const onBlurDistant = ev => {
    let blurValue;
    const typedValue = formatCurrency(distantRate);

    if (distantRate) {
      blurValue = typedValue;
      upstreamOnChange({
        [rowKeyDistant]: blurValue,
        payIdleDaysDistantAtScale: false,
        payIdleDaysDistantAtNegotiated: true,
        payAtScaleDistant: false,
      });
    }

    if (distantRate && !studioRate) {
      blurValue = typedValue;
      // copy to studio
      upstreamOnChange({
        [rowKeyStudio]: blurValue,
      });
      handleAutoScaleUpdate(blurValue, 'studio');
    }

    if ((!typedValue && studioRate) || (!distantRate && studioRate)) {
      blurValue = studioRate;
      upstreamOnChange({
        [rowKeyDistant]: blurValue,
      });
      handleAutoScaleUpdate(blurValue, 'distant');
    }
    setDistantRate(blurValue);
    upstreamOnChange({
      tableErrors: {
        isRateBelowScale: blurValue < payrollScaleValDistant && isCNCSignatory,
      },
    });
  };
  const isPayIdleScaleDistant =
    payIdleDaysDistantAtScale ||
    (isScaleDistant && !payIdleDaysDistantAtNegotiated);
  const rateErrorMessage = formErrors?.rate;
  const cncErrorMessage = formErrors?.rateBelowScale
    ? 'Rate is below scale'
    : null;
  const errorMessage = rateErrorMessage || cncErrorMessage;

  const isStudioBelowScaleWarning = studioRate < payrollScaleValStudio;
  const isDistantBelowScaleWarning = distantRate < payrollScaleValDistant;
  const isBelowScaleWarning =
    (rowKey !== 'payIdle' && isStudioBelowScaleWarning) ||
    isDistantBelowScaleWarning;
  return (
    <>
      <TableRow>
        <TableCell className={classes.cellTitle}>
          <div className={classes.errorContainer}>
            <Typography
              className={classes.hoursPerDay}
              data-test-id={`oncallradiorow-rowTitle`}
            >
              {rowTitle}
            </Typography>
            {isBelowScaleWarning && !errorMessage && isPayWeekly && (
              <Typography
                color="warning"
                variant="caption"
                className={classes.warningLabel}
                data-test-id="oncall-InputField-warning-message"
              >
                <WarningIcon className={classes.warningIcon} />
                Rate is below scale
              </Typography>
            )}
            {errorMessage && isPayWeekly && !studioRate && (
              <Typography
                color="warning"
                variant="caption"
                className={classes.errorLabel}
                data-test-id={`oncallradiorow-error-message-${rowKey}`}
              >
                <WarningIcon className={classes.errorIcon} />
                {errorMessage}
              </Typography>
            )}
          </div>
        </TableCell>
        <TableCell
          className={
            isStudioDisabled ? classes.greyedOutArea : classes.rightCellArea
          }
        >
          {!isStudioDisabled && (
            <>
              {' '}
              <div className={classes.itemContainer}>
                <Typography className={classes.dollar}>$</Typography>
                <MaskedInput
                  mask={ratePerWeekOnCallMask}
                  className={classes.inputSpacing}
                  value={studioRate || ''}
                  onChange={onChangeStudio}
                  onBlur={onBlurStudio}
                  error={isStudioBelowScaleWarning}
                  render={(ref, props) => (
                    <TextField
                      inputRef={ref}
                      {...props}
                      variant="standard"
                      disabled={isReadOnly || isScaleStudioDisabled}
                      InputProps={{
                        className:
                          errorMessage && !studioRate
                            ? classes.errorUnderline
                            : '',
                        inputProps: {
                          'data-test-id': `oncallradiorow-studioinput`,
                        },
                      }}
                    />
                  )}
                />
                <Typography className={classes.hoursCopy}>
                  <span>{isPayWeekly ? '/week' : '/hours'}</span>
                </Typography>
              </div>
              {!isRadioRowDisabled && (
                <div className={classes.radioContainer}>
                  <TableCell className={classes.radioGroupCell}>
                    <RadioGroup
                      className={classes.radioBtns}
                      value={isScaleStudio ? 'yes' : 'no'}
                      onChange={() => handleScaleChange('studio')}
                    >
                      <FormControlLabel
                        classes={{ label: classes.label }}
                        value="yes"
                        control={CustomRadio}
                        label="Scale Rate"
                        data-test-id={`oncallradiorow-studioscale-radio-yes`}
                        disabled={isReadOnly || !payrollScaleValStudio}
                      />
                      <FormControlLabel
                        classes={{ label: classes.label }}
                        value="no"
                        control={CustomRadio}
                        label="Negotiated Rate"
                        data-test-id={`oncallradiorow-studionegotiated-radio-no`}
                        disabled={isReadOnly || !payrollScaleValStudio}
                      />
                    </RadioGroup>
                  </TableCell>
                </div>
              )}
            </>
          )}
        </TableCell>
        <TableCell className={classes.rightCellArea}>
          {!isPayIdle && (
            <div className={classes.itemContainer}>
              <Typography className={classes.dollar}>$</Typography>
              <MaskedInput
                mask={ratePerWeekOnCallMask}
                className={classes.inputSpacing}
                value={distantRate || ''}
                onChange={onChangeDistant}
                onBlur={onBlurDistant}
                error={isDistantBelowScaleWarning}
                render={(ref, props) => (
                  <TextField
                    inputRef={ref}
                    {...props}
                    variant="standard"
                    disabled={isReadOnly || isScaleDistantDisabled}
                    InputProps={{
                      className:
                        errorMessage && !distantRate
                          ? classes.errorUnderline
                          : '',
                      inputProps: {
                        'data-test-id': `oncallradiorow-distantinput-${rowKey}`,
                      },
                    }}
                  />
                )}
              />
              <Typography className={classes.hoursCopy}>
                <span>{isPayWeekly ? '/week' : '/hours'}</span>
              </Typography>
            </div>
          )}
          {isPayIdle && !isRadioRowDisabled && (
            <div className={classes.percentageContainer}>
              <div>
                <RadioGroup
                  className={classes.radioBtns}
                  value={payIdleDaysDistantAt10th ? 'no' : 'yes'}
                  onChange={ev => handlePercentageChange(ev)}
                >
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    value="yes"
                    control={CustomRadio}
                    label="1/12th"
                    data-test-id={`oncallradiorow-distantscale-radio-yes`}
                    disabled={isReadOnly}
                  />
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    value="no"
                    control={CustomRadio}
                    label="1/10th"
                    data-test-id={`oncallradiorow-distantscale-radio-no`}
                    disabled={isReadOnly}
                  />
                </RadioGroup>
              </div>
              <StyledOfTypography>of</StyledOfTypography>
              <div>
                <RadioGroup
                  className={classes.radioBtns}
                  value={isPayIdleScaleDistant ? 'yes' : 'no'}
                  onChange={ev => handlePayIdleChange(ev)}
                >
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    value="yes"
                    control={CustomRadio}
                    label="Scale Rate"
                    data-test-id={`oncallradiorow-distantnegotiated-radio-yes-${rowKey}`}
                    disabled={isReadOnly || !isScaleDistant}
                  />
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    value="no"
                    control={CustomRadio}
                    label="Negotiated Rate"
                    data-test-id={`oncallradiorow-distantnegotiated-radio-no-${rowKey}`}
                    disabled={isReadOnly || payAtScaleDistant}
                  />
                </RadioGroup>
              </div>
            </div>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default WeeklyInputRadioRow;
