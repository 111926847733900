import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { useQuery, graphql } from 'react-apollo';
import { withStyles } from '@mui/styles';
import {
  FormControlLabel,
  Typography,
  Button,
  Grid,
  Switch,
  Stack,
  Box,
} from '@mui/material';
import {
  AddCircleOutline as AddIcon,
  FolderOpenOutlined as FolderIcon,
} from '@mui/icons-material';
import * as Queries from 'common/apollo/queries';
import useSyncedState from 'common/hooks/useSyncedState';
import Loader from 'common/components/Loader';
import useProjectTemplateStudioPlusDirectory from './useProjectTemplateStudioPlusDirectory';
import StudioPlusFolderList from '../../StudioPlusFolderSelector/StudioPlusFolderList';
import StudioPlusFolderSelectorModal from '../../StudioPlusFolderSelector/StudioPlusFolderSelectorModal';
import withRouteHelpers from 'common/hoc/withRouteHelpers/withRouteHelpers';
import StudioPlusFolderListError from '../../StudioPlusFolderSelector/StudioPlusFolderListError';
import * as Mutations from 'common/apollo/mutations';

const styles = theme => ({
  container: {
    width: '100%',
    paddingTop: theme.spacing(3.5),
  },
  pathContainer: {
    marginTop: 12,
  },
  button: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: '#0000FF',
  },
  buttonText: {
    paddingLeft: 6,
    fontWeight: '500 !important',
    textTransform: 'none !important',
    color: '#004eeb !important',
  },
  actions: {
    marginLeft: '10px !important',
    cursor: 'pointer !important',
    fontWeight: '500 !important',
    fontSize: '1.1rem !important',
    color: '#155eef !important',
  },
});

const DocumentDestination = props => {
  const {
    classes,
    projectTemplateId,
    projectId,
    onSetGqlCallback,
    onSave,
    updateDoc,
  } = props;
  const {
    data: { projectStudioPlusDirectories: directories = [] } = {},
    loading: dirsLoading,
    error: dirsError,
  } = useQuery(Queries.projectStudioPlusDirectories, {
    variables: { projectId },
  });
  const {
    data: {
      studioPlusDirectories,
      studioPlusIntegrationEnabled,
      sendProvisionalDealMemo,
      fields: templateFields,
    } = {},
    loading: studioPlusLoading,
    refetch,
  } = useProjectTemplateStudioPlusDirectory({ projectTemplateId });

  const [selectedDirectories, setSelectedDirectories] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [error, setError] = useState(null);
  const keysToCheck = ['directoryId', 'fieldId', 'value'];

  const defaultIsEnabled =
    studioPlusIntegrationEnabled && !!selectedDirectories?.length;

  const [isEnabled, setIsEnabled] = useSyncedState(
    defaultIsEnabled,
    defaultIsEnabled,
  );

  useEffect(() => {
    if (studioPlusDirectories) {
      setSelectedDirectories(studioPlusDirectories);
    }
  }, [studioPlusDirectories]);

  const formatData = directories =>
    directories.map(({ directoryId, fieldId, value, sendWithProvisional }) => ({
      directoryId,
      fieldId,
      value,
      sendWithProvisional,
    }));

  // Whenever the data changes, update the callback to fire the required mutation.
  // This callback will be called by the grandparent when the Save button is clicked.
  // This is not a good design but the project template page is a mess.
  const registerSaveCallback = (
    directories,
    isEnabled,
    duplicateError = false,
  ) => {
    if (duplicateError) {
      updateDoc({
        id: projectTemplateId,
        key: 'studioPlusError',
        value: 'Duplicate Destination Configuration',
      });
    } else {
      updateDoc({ id: projectTemplateId, key: 'studioPlusError', value: null });
    }
    onSetGqlCallback(async () => {
      await onSave(formatData(directories), isEnabled);
      if (refetch) {
        const result = await refetch();
        return result;
      }
      return null;
    });
  };

  const hasDuplicates = (arr, keys) => {
    const seen = new Set();

    return arr.some(obj => {
      const key = keys.map(k => obj[k]).join('|');

      if (seen.has(key)) {
        return true; // Duplicate found
      }
      seen.add(key);
      return false;
    });
  };

  const checkDuplicates = directories => {
    const isDuplicate = hasDuplicates(directories, keysToCheck);
    if (isDuplicate) {
      setError('Duplicate Destination Configuration');
    } else {
      setError(null);
    }
    return isDuplicate;
  };

  const onChangeDirectory = directory => {
    const updatedDirectories = [
      ...selectedDirectories,
      { directoryId: directory },
    ];
    const isDuplicate = checkDuplicates(updatedDirectories);
    setSelectedDirectories(updatedDirectories);
    setIsEnabled(true);
    registerSaveCallback(updatedDirectories, true, isDuplicate);
  };

  const changeSelectedDirectory = directories => {
    const isDirsEmpty = !!directories?.length;
    const isDuplicate = checkDuplicates(directories);
    setSelectedDirectories(directories);
    setIsEnabled(isDirsEmpty);
    registerSaveCallback(directories, isDirsEmpty, isDuplicate);
  };

  if (studioPlusLoading) return <Loader />;

  return (
    <div className={classes.container}>
      <Box sx={{ mb: 3 }}>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Box>
            <Typography variant="h6" gutterBottom>
              Studio+ Destination
            </Typography>
            <Typography variant="body1">
              This document will be sent to the following destination in
              Studio+.
            </Typography>
          </Box>
          <FormControlLabel
            label="Enabled"
            control={
              <Switch
                checked={isEnabled}
                disabled={dirsError}
                onClick={() => {
                  setIsEnabled(!isEnabled);
                  registerSaveCallback(selectedDirectories, !isEnabled);
                }}
                data-test-id="DocumentDestination-enabled"
                color="primary"
              />
            }
          />
        </Stack>
      </Box>
      {dirsLoading && <Loader />}
      {dirsError && <StudioPlusFolderListError error={dirsError} />}
      {!dirsLoading && !dirsError && (
        <>
          {isEnabled && (
            <>
              <StudioPlusFolderList
                projectId={projectId}
                directories={directories}
                onChange={changeSelectedDirectory}
                templateFields={templateFields}
                selectedDirectories={selectedDirectories}
                setIsEnabled={setIsEnabled}
                projectTemplateId={projectTemplateId}
                sendProvisionalDealMemo={sendProvisionalDealMemo}
              />
              {selectedDirectories?.length > 0 ? (
                <Button
                  className={classes.button}
                  variant="text"
                  onClick={() => setIsDialogOpen(true)}
                >
                  <AddIcon sx={{ color: '#004eeb' }} />{' '}
                  <Typography
                    className={classes.buttonText}
                    data-test-id="DocumentDestination-add"
                  >
                    Add destination folder
                  </Typography>
                </Button>
              ) : (
                <Stack direction="row" spacing={1} alignItems="center">
                  <FolderIcon
                    sx={{
                      color: '#667085',
                      marginRight: '6px',
                      fontSize: '1.7rem',
                    }}
                  />
                  <Typography sx={{ fontSize: '1.1rem', color: '#354054' }}>
                    Destination folder:
                  </Typography>
                  <Typography
                    onClick={() => setIsDialogOpen(true)}
                    color={'primary'}
                    className={classes.actions}
                    data-test-id="DocumentDestination-add"
                  >
                    Choose destination folder
                  </Typography>
                </Stack>
              )}
            </>
          )}
          {error && (
            <Typography variant="body1" color="error" sx={{ ml: 1.2 }}>
              {error}
            </Typography>
          )}
          <Grid>
            <StudioPlusFolderSelectorModal
              directories={directories}
              onClose={() => setIsDialogOpen(false)}
              onSelect={onChangeDirectory}
              open={isDialogOpen}
            />
          </Grid>
        </>
      )}
    </div>
  );
};

const withUpdateProjectTemplate = graphql(Mutations.updateProjectTemplate, {
  props: ({ mutate, ownProps: { projectTemplateId } }) => ({
    onSave: (directories, isEnabled) =>
      mutate({
        variables: {
          projectTemplateId: parseInt(projectTemplateId),
          studioPlusDirectories: directories,
          studioPlusIntegrationEnabled: isEnabled,
        },
      }),
  }),
});

export default compose(
  withRouteHelpers,
  withUpdateProjectTemplate,
  withStyles(styles),
)(DocumentDestination);
