import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CrewFilterMenu from './CrewFilterMenu';
import CrewSortMenu from './CrewSortMenu';
import FilterListIcon from '@mui/icons-material/FilterList';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import Box from '@mui/material/Box';

const CrewFilter = props => {
  const { filters, onChange, classes = {}, sortCrewMembers } = props || {};
  const [menuOpen, setMenuOpen] = useState(false);
  const [sortMenuOpen, setSortMenuOpen] = useState(false);

  const toggleMenu = ({ currentTarget } = {}) => {
    if (menuOpen) return setMenuOpen(false);
    return setMenuOpen(currentTarget);
  };

  const handleSortMenu = ({ currentTarget }) => {
    if (sortMenuOpen) return setSortMenuOpen(false);
    return setSortMenuOpen(currentTarget);
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Box display={'flex'} alignItems={'center'} sx={{ mr: 1 }}>
          <IconButton
            aria-label="Filter Menu"
            onClick={toggleMenu}
            classes={{ root: classes.button }}
            data-test-id="CrewFilter-filterButton"
          >
            <FilterListIcon />
          </IconButton>
          <Typography variant="subtitle1">Filter </Typography>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <IconButton
            onClick={handleSortMenu}
            classes={{ label: classes.sortIconLabel, root: classes.button }}
            data-test-id="CrewFilter-sortButton"
          >
            <SwapVertIcon />
          </IconButton>
          <Typography variant="subtitle1">Sort </Typography>
        </Box>
      </div>
      <CrewFilterMenu
        open={menuOpen}
        onClose={toggleMenu}
        anchor={menuOpen}
        filters={filters}
        onChange={onChange}
      />
      <CrewSortMenu
        open={sortMenuOpen}
        onClose={handleSortMenu}
        anchor={sortMenuOpen}
        filters={filters}
        onChange={sortCrewMembers}
      />
    </React.Fragment>
  );
};

export default CrewFilter;
