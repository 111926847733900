import React, { Component } from 'react';

import T from 'common/oldJavascripts/utils/i18n';
import Modal from 'common/oldJavascripts/components/Base/Modal';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Header from 'common/oldJavascripts/components/Base/Header';
import Button from 'common/oldJavascripts/components/Base/Button';
import NonEmployeeListItem from './NonEmployeeListItem';
import Departments from '../Departments/Index';
import DOMPurify from 'dompurify';
import withApi from 'common/hoc/withApi';
import { compose } from 'redux';

import ajax from 'common/utilities/ajax.js';

// Mui Components
import {
  Paper,
  Grid,
  Typography,
  List,
  FormControlLabel,
  Checkbox,
  Collapse,
} from '@mui/material';
import {
  ChevronRight as MoreIcon,
  KeyboardArrowDown as LessIcon,
} from '@mui/icons-material';
// HoC
import { withStyles } from '@mui/styles';

const styles = {
  listRoot: {
    border: '1px solid #e6e7ea',
    padding: '0 !important',
    marginBottom: '20px !important',
  },
  bold: {
    fontWeight: 'bold',
  },
  inlineTitle: {
    display: 'inline-block',
  },
  controlLabel: {
    float: 'right',
  },
  checkbox: {
    padding: 0,
  },
  collapse: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: 10,
  },
  header: {
    marginBottom: 0,
  },
};

class UsersAndDepartments extends Component {
  static queries = {
    departments: {
      info(params, related) {
        const routerParams = related['/router/params'];

        return {
          id: `/v2/projects/${routerParams.projectId}/departments`,
          expires: true,
        };
      },
    },
  };

  state = {
    isWarningOpen: false,
    isLoanOutRestricted: DOMPurify.sanitize(this.props.enforceLoanOut),
    open1: false,
    open2: false,
  };

  renderList = () => {
    const {
      classes,
      nonEmployees: { items: nonEmployees = [] } = {},
    } = this.props;
    return (
      <List className={classes.listRoot}>
        {nonEmployees.map(nonEmployee => (
          <NonEmployeeListItem
            key={nonEmployee.id}
            params={{ id: nonEmployee.id }}
          />
        ))}
      </List>
    );
  };

  changeLoanOut = event => {
    const { checked } = event.target;
    const { projectId } = this.props;
    this.setState({ isLoanOutRestricted: checked });

    ajax
      .post(
        `/projects/${projectId}/enforce-loan-out`,
        { payload: { enforce_loan_out: checked } },
        { json: true },
      )
      .then(
        () => {},
        () => {
          this.setState({ isLoanOutRestricted: !checked, isWarningOpen: true });
        },
      );
  };

  handleOpen = open => {
    this.setState({ [`open${open}`]: !this.state[`open${open}`] });
  };

  render() {
    const {
      classes,
      nonEmployees: { items: nonEmployees = [] } = {},
      projectId,
      detailSubDataType,
      enforceMask,
      accountMask,
    } = this.props;

    const { isLoanOutRestricted, isWarningOpen, open1, open2 } = this.state;

    const departmentsCount = this.props.departments?.data?.items?.length || 0;
    console.log('test', this.props);
    return (
      <div>
        <BoxItem divider={true}>
          <Header>
            <Header.Title>
              <span data-test-id="UsersAndDepartments-title">
                {T('project.documents.manage_users.title')}
              </span>
            </Header.Title>
          </Header>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper elevation={0}>
                <div
                  className={classes.collapse}
                  onClick={() => this.handleOpen(1)}
                  data-test-id="Project-nonEmployees"
                >
                  {open1 ? (
                    <LessIcon className="expansion-icons" />
                  ) : (
                    <MoreIcon className="expansion-icons" />
                  )}
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.header}
                  >
                    {T('project.documents.manage_users.non_employees.title') +
                      '(' +
                      nonEmployees.length +
                      ')'}
                  </Typography>
                </div>
                <Collapse in={open1} timeout="auto">
                  {!!nonEmployees.length && this.renderList()}
                </Collapse>
                <Button to={`/projects/${projectId}/invitations/new`}>
                  {T('project.documents.manage_users.non_employees.add')}
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={0}>
                <div
                  className={classes.collapse}
                  onClick={() => this.handleOpen(2)}
                  data-test-id="Project-departments"
                >
                  {open2 ? (
                    <LessIcon className="expansion-icons" />
                  ) : (
                    <MoreIcon className="expansion-icons" />
                  )}
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.header}
                  >
                    {T('project.documents.manage_users.departments.title') +
                      '(' +
                      departmentsCount +
                      ')'}
                  </Typography>
                </div>
                <FormControlLabel
                  classes={{ root: classes.controlLabel }}
                  control={
                    <Checkbox
                      checked={isLoanOutRestricted}
                      onChange={this.changeLoanOut}
                      classes={{ root: classes.checkbox }}
                      color="secondary"
                    />
                  }
                  label="Restrict selectable occupations for loan-out hires"
                  data-test-id={`Project-restrictLoanOut-${projectId}`}
                />
                <Collapse in={open2} timeout="auto">
                  <Departments
                    detailSubDataType={detailSubDataType}
                    enforceMask={enforceMask}
                    accountMask={accountMask}
                    departments={this.props.departments}
                  />
                </Collapse>
              </Paper>
            </Grid>
          </Grid>
        </BoxItem>

        {isWarningOpen && (
          <Modal>
            <Typography variant="h6" gutterBottom>
              This project does not yet have any loan-out eligible occupations
              defined.
            </Typography>
            <Button
              onClick={() => {
                this.setState({ isWarningOpen: false });
              }}
              data-test-id="Project-loanOutWarning"
            >
              Close
            </Button>
          </Modal>
        )}
      </div>
    );
  }
}

export default compose(withApi, withStyles(styles))(UsersAndDepartments);
