import React, { useState } from 'react';
import { Table, TableCell, TableRow } from '@mui/material';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { ratePerHourMask } from '../../helpers/textFieldNumberMasks';
import MaskedInput from 'react-text-mask';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import formatCurrency from 'studio/components/OfferPage/helpers/formatCurrency';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 40,
    marginBottom: 40,
  },
  cellTitle: {
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dollar: {
    width: '10%',
    fontSize: 14,
    marginTop: 7,
    color: '#696969',
    paddingLeft: 14,
    fontFamily: 'Roboto, Helvetica, Helvetica Neue, san-serif',
  },
  tableSpacing: {
    marginTop: 16,
    marginBottom: 20,
    border: '1px solid #0000FF',
  },
  topSpacing: {
    marginTop: 20,
  },
  hoursCopy: {
    marginTop: 7,
    paddingRight: 12,
    color: '#525252',
    fontSize: 16,
    fontWeight: 'normal',
  },
  errorIcon: {
    color: 'red',
    position: 'relative',
    top: 4,
    right: 8,
  },
  errorLabel: {
    color: 'red',
    fontSize: 14,
    textAlign: 'center',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  hoursPerDay: {
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
  },
  label: {
    color: '#262626',
    fontSize: 16,
  },
  rightCellArea: {
    width: '41%',
    backgroundColor: '#FFFFFF',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  fieldArea: {
    width: '43%',
    backgroundColor: '#FFFFFF!important',
  },
  input: {
    '& input': {
      textAlign: 'center',
    },
  },
}));

const DailyRateTable = props => {
  const {
    onChange: upstreamOnChange,
    formData = {},
    formErrors = {},
    isReadOnly = false,
  } = props;
  const { rate } = formData;
  const classes = useStyles();
  const formattedRate = rate ? formatCurrency(rate) : null;
  const [dailyRate, setDailyRate] = useState(formattedRate);

  const getTypedValue = (ev = {}, formKey) => {
    const { target: { value: rawValue } = {} } = ev;
    let value = rawValue;
    if (rawValue === '' || rawValue === '0') value = '';
    return value;
  };

  const onChange = (ev = {}) => {
    const typedValue = getTypedValue(ev);
    setDailyRate(typedValue);
  };

  const onBlur = () => {
    if (dailyRate) {
      setDailyRate(formatCurrency(dailyRate));
      upstreamOnChange({
        dailyRate,
        rate: dailyRate,
        isTermsOfEmploymentV2: true,
        guaranteedHoursFrequency: 'daily',
      });
    }
  };

  return (
    <>
      <Table>
        <TableRow>
          <TableCell className={classes.cellTitle}>
            <div className={classes.errorContainer}>
              <Typography
                className={classes.hoursPerDay}
                data-test-id="dailyRate-title"
              >
                Daily Rate
              </Typography>
              {formErrors.rate && (
                <Typography
                  color="warning"
                  variant="caption"
                  className={classes.errorLabel}
                >
                  <WarningIcon className={classes.errorIcon} />
                  {formErrors.rate}
                </Typography>
              )}
            </div>
          </TableCell>
          <TableCell className={classes.rightCellArea}>
            <div className={classes.itemContainer}>
              <Typography className={classes.dollar}>$</Typography>
              <MaskedInput
                mask={ratePerHourMask}
                value={dailyRate || ''}
                onChange={onChange}
                onBlur={onBlur}
                className={classes.input}
                render={(ref, props) => (
                  <TextField
                    inputRef={ref}
                    {...props}
                    variant="standard"
                    disabled={isReadOnly}
                    InputProps={{
                      inputProps: {
                        'data-test-id': 'DailyRate-input',
                      },
                    }}
                  />
                )}
              />
              <Typography className={classes.hoursCopy} />
            </div>
          </TableCell>
        </TableRow>
      </Table>
    </>
  );
};

export default DailyRateTable;
