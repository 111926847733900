// Value is blank if it is null, undefined, an empty string, or a bunch of whitespace
const isBlank = val =>
  val == null ||
  (typeof val === 'string' && !val.trim()) ||
  (typeof val === 'object' &&
    !Array.isArray(val) &&
    (Object.keys(val).length === 0 ||
      Object.values(val).every(v => v === false)));

export default isBlank;
