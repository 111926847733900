import React from 'react';

// HoC
import { withStyles } from '@mui/styles';

// Commponents
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';

const styles = theme => ({
  root: {
    width: '180px',
  },
  menuRoot: {
    fontSize: '1.1rem',
    fontWeight: 500,
    paddingLeft: '20px',
  },
  listHeader: {
    fontSize: '1.2rem',
    color: '#cecece',
    lineHeight: '30px',
    paddingLeft: '20px',
  },
});

const CrewSortMenu = props => {
  const { classes, onChange: upstreamOnChange, open, anchor, onClose } = props;

  return (
    <Menu
      id="crew-sort-menu"
      data-test-id="CrewSortMenu"
      anchorEl={anchor ? anchor : undefined}
      open={open}
      onClose={onClose}
      classes={{ paper: classes.root }}
    >
      <ListSubheader classes={{ root: classes.listHeader }}>
        Sort By
      </ListSubheader>
      <MenuItem
        classes={{ root: classes.menuRoot }}
        onClick={() => upstreamOnChange('created_at')}
        data-test-id="CrewSortMenu-newest"
      >
        Newest
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuRoot }}
        onClick={() => upstreamOnChange('asc')}
        data-test-id="CrewSortMenu-asc"
      >
        A-Z
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuRoot }}
        onClick={() => upstreamOnChange('desc')}
        data-test-id="CrewSortMenu-desc"
      >
        Z-A
      </MenuItem>
    </Menu>
  );
};

export default withStyles(styles)(CrewSortMenu);
