import React, { Component } from 'react';

import { withStyles } from '@mui/styles';
import classNames from 'class-names';
import { compose } from 'redux';
import withRouteHelpers from 'common/hoc/withRouteHelpers';

const styles = theme => ({
  root: {
    padding: '20px',
    width: '100%',
    height: '100%',
    flex: 1,
    overflow: 'auto',
  },
  globalNav: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '70px',
    },
  },
  wrapper: { margin: '0 auto', maxWidth: '1325px' },
  wideWrapper: { margin: '0 auto', maxWidth: '1550px' },
  fullWidth: { maxWidth: 'none' },
  extraPadding: {
    paddingBottom: 100,
  },
  compact: {
    maxWidth: 400,
    padding: '100px 0',
  },
});

const wideTemplateIncludeUrls = ['offers', 'approvals'];

class LayoutContent extends Component {
  render() {
    const {
      classes,
      full,
      children,
      className,
      extraPadding,
      compact,
      wrapperProps = {},
      globalNavFlag = false,
      'data-test-id': dataTestId,
      routerPath: pathname,
    } = this.props;

    const pathnameArray = pathname.split('/');
    const path = pathnameArray[pathnameArray.length - 1];
    const wideTable = wideTemplateIncludeUrls.includes(path);
    // Allow classname for wrapper to be passed in
    // But remove it from the props that should be passed down
    const { className: wrapperClassName } = wrapperProps;
    const wrapperPropsCopy = { ...wrapperProps };
    delete wrapperPropsCopy.className;

    const childrenWrapperClass = classNames(
      {
        [classes.wrapper]: !wideTable,
        [classes.wideWrapper]: wideTable,
        [classes.fullWidth]: full,
        [classes.extraPadding]: extraPadding,
        [classes.compact]: compact,
      },
      wrapperClassName,
    );
    const contentClass = classNames(className, classes.root, {
      [classes.globalNav]: globalNavFlag,
    });

    return (
      <div
        className={contentClass}
        ref={node => (this.node = node)}
        data-test-id={dataTestId}
      >
        <div className={childrenWrapperClass} {...wrapperPropsCopy}>
          {children}
        </div>
      </div>
    );
  }

  scrollTop() {
    this.node.scrollTop = 0;
  }
}

export default compose(withRouteHelpers, withStyles(styles))(LayoutContent);
