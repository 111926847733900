import React from 'react';
import OnCallInputField from './OnCallInputField';
import OnCallInputRadioRow from './OnCallInputRadioRow';
import { Table, TableCell, TableRow } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import WarningIcon from '@mui/icons-material/Warning';

const useStyles = makeStyles(() => ({
  tableRoot: {
    marginTop: 10,
    width: '100%',
  },
  tableSpacing: {
    marginBottom: 16,
  },
  headerTitle: {
    width: '30%',
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 'medium !important',
    fontWeight: 'bold !important',
    border: '2px solid #BECFFE',
    textAlign: 'center !important',
  },
  radioContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  radioGroupCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    borderBottom: 'none !important',
    paddingTop: 5,
    paddingBottom: 2,
  },
  radio: {
    '&$checked': {
      color: '#0000FF',
    },
  },
  checked: {
    color: '#0000FF',
    '&, & + $label': {
      color: '#0000FF !important',
      fontSize: 16,
    },
  },
  alignCopy: {
    marginTop: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  scaleCopy: {
    fontWeight: 'bold',
    color: '#525252',
    fontSize: '14px',
  },
  label: {
    fontSize: '16px',
  },
}));

const theme = createTheme({
  overrides: {
    MuiInput: {
      input: {
        fontSize: 16,
        fontWeight: 'normal',
        textAlign: 'center',
        width: 196,
        top: 3,
        color: '#525252',
      },
      underline: {
        width: 196,
        color: '#696969',
        '&:before': {
          borderBottom: `1px solid #8D8D8D`,
        },
        '&$focused:not($disabled):before': {
          borderBottom: `2px solid #0000FF`,
        },
        '&:after': {
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          borderBottom: `2px solid #0000FF`,
        },
        '&$error:not($disabled):before': {
          borderBottom: `1px solid #FFBF00`,
        },
      },
      disabled: {
        '&:before': {
          borderBottom: `1px solid #C6C6C6`,
        },
      },
    },
    MuiFormGroup: {
      root: {
        flexWrap: 'no-wrap',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
      },
    },
  },
});

const DEFAULT_HOURS = 65;

const StyledRadioGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
});

const RateTable = props => {
  const classes = useStyles();
  const {
    formData = {},
    onChange: upstreamOnChange,
    scaleRates,
    contracts = [],
    isContractsLoading = false,
    isReadOnly = false,
  } = props;

  const {
    rate,
    rateDistant,
    payAtScale,
    payAtScaleDistant,
    guaranteedHours,
    guaranteedHoursDistant,
    payIdleDaysDistantAt10th,
    payIdleDaysDistantAt12th,
    payIdleDaysDistantAtScale,
    payIdleDaysDistantAtNegotiated,
  } = formData;

  let isCNCSignatory,
    signatoryID = null;
  if (Array.isArray(contracts)) {
    const pensionContract = contracts.find(
      contract => contract.type === 'pension',
    );
    const pensionArr = pensionContract?.contracts || [];
    if (pensionArr.length > 0) {
      const contract = pensionArr.find(contract => contract.signatory);
      signatoryID = contract?.signatory?.id;
      isCNCSignatory = signatoryID === 'C';
    }
  }

  const payWeeklyKeys = {
    rowTitle: 'Rate Per Week',
    rowKey: 'payWeekly',
    rowKeyStudio: 'rate',
    rowKeyDistant: 'rateDistant',
    rateStudio: rate,
    rateDistant,
    payrollScaleValStudio: scaleRates?.rate,
    payrollScaleValDistant: scaleRates?.rateDistant,
    isRadioRowDisabled: true,
  };

  const payIdleKeys = {
    rowTitle: 'Pay Idle Days at',
    rowKey: 'payIdle',
    rowKeyDistant: 'payIdleDaysDistant',
    isStudioDisabled: true,
    scaleKeyDistant: 'payIdleDaysDistantAtScale',
    rateDistant,
    payrollScaleValStudio: scaleRates?.rate,
    payrollScaleValDistant: scaleRates?.rateDistant,
    payAtScaleDistant,
    payIdleDaysDistantAt10th,
    payIdleDaysDistantAt12th,
    payIdleDaysDistantAtScale,
    payIdleDaysDistantAtNegotiated,
  };

  let isSignatoryAbsentMessage;
  if (!signatoryID && !isReadOnly && !isContractsLoading) {
    isSignatoryAbsentMessage =
      'Sorry, we’re not finding the scale rate. Please check with your C&C Coordinator.';
  }

  const CustomRadio = (
    <Radio
      classes={{
        root: classes.radio,
        checked: classes.checked,
      }}
    />
  );

  const payrollScaleValStudio = parseFloat(scaleRates?.rate);
  const payrollScaleValDistant = parseFloat(scaleRates?.rateDistant);

  const payrollGuaranteedHoursStudioScale = parseFloat(
    scaleRates?.guaranteedHours,
  );
  const payrollGuaranteedHoursDistantScale = parseFloat(
    scaleRates?.guaranteedHoursDistant,
  );

  const isScaleStudioBtn = payrollScaleValStudio > 0;
  const isScaleDistantBtn = payrollScaleValDistant > 0;

  const handleScaleChange = (ev, radioKey) => {
    const { target: { value: rawValue } = {} } = ev;
    const isStudioRadioKey = radioKey === 'studio';
    const isDistantRadioKey = radioKey === 'distant';

    const scaleTitle = isStudioRadioKey ? 'payAtScale' : 'payAtScaleDistant';
    const isScaleSelected = rawValue === 'yes';

    upstreamOnChange({
      [scaleTitle]: isScaleSelected,
      isTermsOfEmploymentV2: true,
      guaranteedHoursFrequency: 'weekly',
    });

    if (isScaleSelected && isStudioRadioKey) {
      upstreamOnChange({
        rate: payrollScaleValStudio,
        guaranteedHours:
          payrollGuaranteedHoursStudioScale > 0
            ? payrollGuaranteedHoursStudioScale
            : DEFAULT_HOURS,
        guaranteedHoursDistant:
          payrollGuaranteedHoursDistantScale > 0
            ? payrollGuaranteedHoursDistantScale
            : DEFAULT_HOURS,
      });
    }
    if (isScaleSelected && isDistantRadioKey) {
      upstreamOnChange({
        rateDistant: payrollScaleValDistant,
        payIdleDaysDistantAtNegotiated: false,
        payIdleDaysDistantAtScale: true,
        guaranteedHoursDistant:
          payrollGuaranteedHoursDistantScale > 0
            ? payrollGuaranteedHoursDistantScale
            : DEFAULT_HOURS,
      });
    } else {
      upstreamOnChange({
        payIdleDaysDistantAtNegotiated: true,
      });
    }
    upstreamOnChange({
      tableErrors: {
        isRateBelowScale: false,
      },
    });
  };

  const isScaleRate = scaleRates?.rate || scaleRates?.rateDistant;
  return (
    <div className={classes.tableRoot}>
      <ThemeProvider theme={theme}>
        {isSignatoryAbsentMessage && (
          <Typography
            color="warning"
            variant="caption"
            className={classes.warningLabel}
            data-test-id="union-signatory-warning-message"
          >
            <WarningIcon className={classes.warningIcon} />
            {isSignatoryAbsentMessage}
          </Typography>
        )}
        <Table className={classes.tableSpacing}>
          <TableRow>
            <TableCell></TableCell>
            <TableCell className={classes.headerTitle}>
              Studio{' '}
              <div className={classes.radioContainer}>
                <TableCell className={classes.radioGroupCell}>
                  <StyledRadioGroup
                    value={payAtScale ? 'yes' : 'no'}
                    onChange={ev => handleScaleChange(ev, 'studio')}
                  >
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      value="yes"
                      control={CustomRadio}
                      label="Scale Rate"
                      disabled={isReadOnly || !isScaleStudioBtn}
                    />
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      value="no"
                      control={CustomRadio}
                      label="Negotiated Rate"
                      disabled={isReadOnly}
                    />
                  </StyledRadioGroup>
                </TableCell>
              </div>
            </TableCell>
            <TableCell className={classes.headerTitle}>
              Distant{' '}
              <div className={classes.radioContainer}>
                <TableCell className={classes.radioGroupCell}>
                  <StyledRadioGroup
                    value={payAtScaleDistant ? 'yes' : 'no'}
                    onChange={ev => handleScaleChange(ev, 'distant')}
                  >
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      value="yes"
                      control={CustomRadio}
                      label="Scale Rate"
                      disabled={isReadOnly || !isScaleDistantBtn}
                    />
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      value="no"
                      control={CustomRadio}
                      label="Negotiated Rate"
                      disabled={isReadOnly}
                    />
                  </StyledRadioGroup>
                </TableCell>
              </div>
            </TableCell>
          </TableRow>
          <OnCallInputRadioRow
            {...props}
            {...payWeeklyKeys}
            isCNCSignatory={isCNCSignatory}
            defaultHours={DEFAULT_HOURS}
          />
          <OnCallInputField
            {...props}
            guaranteedHours={guaranteedHours}
            guaranteedHoursDistant={guaranteedHoursDistant}
            scaleRateStudio={scaleRates?.rate}
            scaleRateDistant={scaleRates?.rateDistant}
            guaranteedHoursScaleStudio={scaleRates?.guaranteedHours}
            guaranteedHoursScaleDistant={scaleRates?.guaranteedHoursDistant}
            isCNCSignatory={isCNCSignatory}
            defaultHours={DEFAULT_HOURS}
          />
        </Table>
        <Table>
          <OnCallInputRadioRow
            {...payIdleKeys}
            {...props}
            isCNCSignatory={isCNCSignatory}
          />
        </Table>
        {isScaleRate ? (
          <div className={classes.alignCopy}>
            <Typography className={classes.scaleCopy}>
              Scale rate shown as of today's date
            </Typography>
            <Typography className={classes.scaleCopy}>
              Scale rate subject to change upon union negotiated increases.
            </Typography>
            <Typography className={classes.scaleCopy}>
              If Rate Per Week is inclusive of idle days, please contact the
              payroll coordinator
            </Typography>
          </div>
        ) : null}
      </ThemeProvider>
    </div>
  );
};
export default RateTable;
