import React from 'react';
import { TableCell, TextField, Typography, TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  sagAmountMask,
  sagTotalAmountMask,
} from '../../helpers/textFieldNumberMasks';
import MaskedInput from 'react-text-mask';
import formatCurrency from 'studio/components/OfferPage/helpers/formatCurrency';

const styles = {
  headerTitle: {
    width: '70%',
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 8,
    textAlign: 'left',
  },
  warningLabel: {
    color: '#FFA800',
    fontSize: 14,
    textAlign: 'center',
    maxWidth: '65%',
    marginBottom: 10,
  },
  warningIcon: {
    color: '#FFA800',
    position: 'relative',
    top: 4,
    right: 8,
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  dollar: {
    width: '10%',
    fontSize: 14,
    marginTop: 7,
    color: '#696969',
    paddingLeft: 14,
    fontFamily: 'Roboto, Helvetica, Helvetica Neue, san-serif',
  },
  inputSpacing: {
    marginLeft: '5%',
    marginRight: '10%',
  },
  errorUnderline: {
    width: 196,
    borderBottom: '1px solid red',
  },
  rightCellArea: {
    width: '30%',
    backgroundColor: '#FFFFFF',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
};

const Rate = ({
  classes,
  errorMessage,
  onChange: upstreamOnChange,
  value: rateValue,
  label,
  disabled = false,
}) => {
  const handleChange = ev => {
    const { target: { value: rawValue } = {} } = ev;
    upstreamOnChange(rawValue);
  };

  const handleBlur = ev => {
    const { target: { value: rawValue } = {} } = ev;
    let value = rawValue > 0 ? formatCurrency(rawValue) : null;
    upstreamOnChange(value);
  };

  return (
    <TableRow>
      <TableCell className={classes.headerTitle}>{label}</TableCell>
      <TableCell className={classes.rightCellArea}>
        <div className={classes.itemContainer}>
          <Typography className={classes.dollar}>$</Typography>
          <MaskedInput
            mask={!disabled ? sagAmountMask : sagTotalAmountMask}
            className={classes.inputSpacing}
            value={rateValue > 0 ? rateValue : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errorMessage ? true : false}
            disabled={disabled}
            name="ratePerDay"
            render={(ref, props) => (
              <TextField
                inputRef={ref}
                {...props}
                variant="standard"
                error={errorMessage ? true : false}
                helperText={errorMessage}
                InputProps={{
                  className: errorMessage ? classes.errorUnderline : '',
                  inputProps: {
                    'data-test-id': `inputtext-rate-${label}`,
                  },
                }}
              />
            )}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default withStyles(styles)(Rate);
