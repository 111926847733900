import React, { useState } from 'react';
import moment from 'moment';
import Button from 'common/oldJavascripts/components/Base/Button';
import Link from 'common/oldJavascripts/components/Base/Link';
import Field from 'common/oldJavascripts/components/Base/Field';
import Form from 'common/oldJavascripts/components/Base/Form';
import FormField from 'common/oldJavascripts/components/Base/FormField';
import DatePicker from 'common/oldJavascripts/components/Base/DatePickerField';
import PrivilegesPicker from './PrivilegesPicker';
import { cleanName } from 'common/oldJavascripts/utils/validateName';
import { isFormComplete } from 'common/oldJavascripts/utils/isFormComplete';
import { Typography, Paper } from '@mui/material';

// HoC
import { compose } from 'redux';
import { withStyles } from '@mui/styles';

// Utilities
import { AsYouType } from 'libphonenumber-js';
import history from 'common/constants/config/history';
import validateEmail from 'common/utilities/validateEmail';
import useInviteUser from '../hooks/useInviteUser';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import withRouteHelpers from 'common/hoc/withRouteHelpers';

const styles = theme => ({
  root: {
    height: 'calc(100% - 122px)',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: 'auto',
    placeItems: 'center',
    overflowY: 'auto',
  },
  paper: {
    width: 500,
    margin: '15px 0px',
  },
  content: {
    padding: '22px 30px',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '60px auto',
  },
  header: {
    height: '40px',
    fontWeight: 700,
    fontSize: '24px',
    color: 'rgb(67, 79, 89)',
  },
  footer: {
    padding: '22px 30px',
    display: 'grid',
    background: '#fbfbfb',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'end',
  },
  datePickerContainer: {
    display: 'grid',
    margin: 0,
  },
  actions: {
    textAlign: 'right',
  },
});

const NewInvitation = ({ routerParams, classes, pushSnackbarNotification }) => {
  const { projectId } = routerParams || {};
  const [inviteUser, loading] = useInviteUser();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    title: '',
    sendDate: moment().format('YYYY-MM-DD'),
    privileges: {},
  });

  const checkSendInviteButtonStatus = () => {
    const requiredFields = [
      'firstName',
      'lastName',
      'email',
      'title',
      'privileges',
    ];
    if (!validateEmail(formData.email)) return true;
    return !isFormComplete(formData, requiredFields);
  };

  const formatPhoneNumber = number => {
    // we do not start formatting the number until it's greater than 4 digits
    // this prevents a bug that prevents the user from deleting their area code
    if (!/(.?\d){4,}/.test(number)) return number;
    const asYouType = new AsYouType('US');
    // return the formatted phone number
    return asYouType.input(number);
  };

  const handleChange = (value, key) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handlePrivileges = (key, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      privileges: {
        ...prevFormData.privileges,
        [key]: value,
      },
    }));
  };

  const submitForm = () => {
    const privileges = Object.keys(formData.privileges).filter(
      priv => formData.privileges[priv],
    );
    const variables = {
      projectId: parseInt(projectId),
      ...formData,
      privileges,
    };

    inviteUser({ variables })
      .then(({ data }) => {
        const { projectAddNonEmployee } = data;
        if (projectAddNonEmployee) {
          pushSnackbarNotification({
            message: 'Invitation sent.',
            variant: SnackbarVariants.SUCCESS,
          });
          history.push(`/projects/${projectId}/invitations`);
        }
      })
      .catch(e => {
        const { graphQLErrors = [] } = e || {};
        const { message = '' } = graphQLErrors[0] || {};
        pushSnackbarNotification({
          message: message,
          variant: SnackbarVariants.ERROR,
        });
      });
  };

  const {
    firstName,
    lastName,
    email,
    phone,
    title,
    sendDate,
    privileges,
  } = formData;
  const sendDateMoment = sendDate ? moment(sendDate, 'YYYY-MM-DD') : moment();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Form onSubmit={submitForm}>
          <div className={classes.content}>
            <Typography className={classes.header}>
              <span data-test-id="New-title">Profile information</span>
            </Typography>
            <span data-test-id="New-firstName">
              <Field
                label="First Name"
                type="text"
                name="firstName"
                value={cleanName(firstName)}
                onChange={value => handleChange(value, 'firstName')}
                required={true}
                id="firstName"
              />
            </span>
            <span data-test-id="New-lastName">
              <Field
                label="Last Name"
                type="text"
                name="lastName"
                value={cleanName(lastName)}
                onChange={value => handleChange(value, 'lastName')}
                required={true}
                id="lastName"
              />
            </span>
            <span data-test-id="New-email">
              <Field
                label="Email"
                type="text"
                name="email"
                value={email}
                onChange={value => handleChange(value, 'email')}
                required={true}
                id="email"
              />
            </span>
            <span data-test-id="New-phone">
              <Field
                label="Phone"
                type="phone"
                name="phone"
                value={phone}
                onChange={value =>
                  handleChange(formatPhoneNumber(value), 'phone')
                }
                id="phone"
              />
            </span>
            <span data-test-id="New-jobTitle">
              <Field
                label="Title"
                type="text"
                name="title"
                value={title}
                onChange={value => handleChange(value, 'title')}
                required={true}
                id="title"
              />
            </span>
            <PrivilegesPicker
              privileges={privileges}
              updatePrivileges={handlePrivileges}
            />
          </div>
          <div className={classes.footer}>
            <div data-test-id="New-sendDate">
              <FormField
                className={classes.datePickerContainer}
                label="Send on:"
                required={true}
                grouped={false}
              >
                <DatePicker
                  name="sendDate"
                  id="sendDate"
                  isClearable={false}
                  selected={sendDateMoment}
                  minDate={moment()}
                  maxDate={moment().add(90, 'days')}
                  onChange={value =>
                    handleChange(
                      value && value.format('YYYY-MM-DD'),
                      'sendDate',
                    )
                  }
                />
              </FormField>
            </div>
            <div className={classes.actions}>
              <Link
                to={`/projects/${projectId}/users`}
                data-test-id="New-cancel"
              >
                cancel
              </Link>
              {' or '}
              <Button
                disabled={checkSendInviteButtonStatus() || loading}
                onClick={() => submitForm()}
                data-test-id="New-invite"
              >
                Send invite
              </Button>
            </div>
          </div>
        </Form>
      </Paper>
    </div>
  );
};

export default compose(
  withStyles(styles),
  withSnackbarNotification,
  withRouteHelpers,
)(NewInvitation);
