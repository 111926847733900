import React from 'react';
import withApi from 'common/hoc/withApi';
import Loader from 'common/components/Loader';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import ProjectTemplates from 'admin/components/ProjectTemplates';
import Ajax from 'common/utilities/ajax';
import { parseErrorMessages } from 'common/oldJavascripts/utils/errorMessageParser';
import { DEFAULT_PER_PAGE } from 'common/oldJavascripts/config.js';
import { withStyles } from '@mui/styles';
import queryParam from 'common/utilities/queryParam';
import transformProjectTemplates from './transformProjectTemplates_NEW';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import classnames from 'classnames';

const styles = theme => ({
  header: {
    height: 20,
  },
  sortName: {
    position: 'absolute',
    width: 60,
    left: 61,
    cursor: 'pointer',
    fontWeight: 700,
  },
  sortRank: {
    position: 'absolute',
    width: 70,
    right: 338,
    cursor: 'pointer',
  },
  sortStudio: {
    position: 'absolute',
    width: 75,
    left: 510,
    cursor: 'pointer',
    fontWeight: 700,
  },
  sortRules: {
    position: 'absolute',
    width: 138,
    right: 575,
    cursor: 'pointer',
    fontWeight: 700,
  },
  sortWorkflows: {
    position: 'absolute',
    width: 92,
    right: 465,
    cursor: 'pointer',
    fontWeight: 700,
  },
  sortIcons: {
    position: 'relative',
    top: 2,
    height: 'auto',
    fontSize: '1.8rem',
    margin: '-7px 0px',
  },
  iconsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  greyIcon: {
    color: '#C6C6C6',
  },
  blueIcon: {
    color: '#0000FF',
  },
});

class DocumentContainerWrapper extends React.Component {
  static queries = {
    projectTemplates: {
      info: (params, related) => {
        const { projectId } = params;
        const { '/router/query': query } = related;
        const cursor = {
          direction: queryParam(query.order, 'asc'),
          page: queryParam(query.page, 1),
          per_page: queryParam(query.perPage, DEFAULT_PER_PAGE),
          sort: queryParam(query.orderBy, 'rank'),
        };
        return {
          id: `/workflow/projects/${projectId}/project_templates_v2`,
          cursor,
        };
      },
    },
  };

  state = {
    approvers: [],
    isDeleting: null,
    isSaving: false,
    isUpdatingRank: null,
    errors: {},
    verifiers: [],
  };

  componentDidMount() {
    this.requestUsers();
    this.props.projectTemplates.reload();
  }

  componentWillReceiveProps(nextProps) {
    const { attachedTemplates: newlyAttachedIds = [] } = nextProps;
    const { onProcessedAttachments, projectTemplates } = this.props;
    if (newlyAttachedIds.length > 0) {
      projectTemplates.reload();
      onProcessedAttachments();
    }
  }

  requestUsers = () => {
    const { projectId } = this.props.params;
    Ajax.get(`/v2/projects/${projectId}/users?role=approver`, {}).then(
      response => {
        const { items: approvers = [] } = response;
        this.setState({ approvers });
      },
    );
    Ajax.get(`/v2/projects/${projectId}/users?role=verifier`, {}).then(
      response => {
        const { items: verifiers = [] } = response;
        this.setState({ verifiers });
      },
    );
  };

  handleDelete = item => {
    const {
      params: { projectId },
      projectTemplates: { reload },
    } = this.props;
    this.setState({ isDeleting: item.id }, () => {
      Ajax.delete(`/workflow/projects/${projectId}/templates/${item.id}`).then(
        () => {
          this.setState({ isDeleting: null });
          reload();
        },
      );
    });
  };

  getOriginalTemplateData = templateId => {
    return this.getTransformedProjectTemplates().find(
      ({ id }) => id === templateId,
    );
  };

  handleUpdate = (updates, updateType = 'default') => {
    const originalData = this.getOriginalTemplateData(updates.id);
    this.setState({ errors: {}, isSaving: true });
    const { projectId } = this.props.params;
    let data = {
      ...updates,
    };
    const { studioPlusError, id: templateId } = updates;
    if (studioPlusError) {
      this.setState({
        errors: {
          message: 'Duplicate Studio+ Destination Configuration',
          id: templateId,
        },
        isUpdatingRank: null,
        isDeleting: null,
        isSaving: false,
      });
      return;
    }
    const {
      auto_assign_rules: { errors: templateErrors = {} },
    } = updates;
    if (templateErrors?.duplicate || templateErrors?.comboNotEligible) {
      this.setState({
        errors: { message: 'Invalid Rule', id: templateId },
        isUpdatingRank: null,
        isDeleting: null,
        isSaving: false,
      });
      return;
    }

    delete updates?.auto_assign_rules?.errors;
    // Transform from component-side model of support docs to server-side model.
    const sd = data.supportDocument;
    if (sd) {
      data.supportingDocumentTitles = [
        {
          documentTitle: sd.name,
          required: sd.isRequired,
          attachToMainDocument: sd.isIncluded,
          rank: 1,
        },
      ];
    } else {
      data.supportingDocumentTitles = [];
    }
    delete data.supportDocument;

    const url =
      updateType === 'default'
        ? `/workflow/projects/${projectId}/templates/${templateId}/v2`
        : `/workflow/projects/${projectId}/templates/${templateId}/status`;
    return Ajax.patch(url, { ...data }, { json: true })
      .then(response => {
        this.setState({ isSaving: false });
        if (updateType === 'default') {
          return true;
        }
        if (updateType === 'rank') {
          this.setState({ isUpdatingRank: templateId }, () => {
            this.saveStatusSuccess();
          });
          return;
        }
        if (updateType === 'active') {
          const {
            projectTemplates: {
              data: { items: { item: documents = [] } = {} } = {},
            },
          } = this.props;
          const { active, id: docId } = updates;
          return documents.map(doc =>
            doc.id === docId ? { ...doc, active } : doc,
          );
        }
        this.saveStatusSuccess();
      })
      .catch(this.saveError(templateId, originalData));
  };

  saveStatusSuccess = () => {
    this.setState({ isUpdatingRank: null });
    this.props.projectTemplates.reload();
  };

  saveError = (templateId, _originalData) => {
    return (err = {}) => {
      const message = parseErrorMessages(err);
      const errors = { message, id: templateId };
      this.setState({
        errors,
        isUpdatingRank: null,
        isDeleting: null,
        isSaving: false,
      });
    };
  };

  getTransformedProjectTemplates = () => {
    const {
      projectTemplates: {
        data: { items: { item: rawTemplates = [] } = {} } = {},
      },
      departments,
      users,
    } = this.props;
    return transformProjectTemplates(rawTemplates, users, departments);
  };

  renderHeader = () => {
    const { classes, amendRouterQuery, getRouterQueryParam } = this.props;
    const sortDir = getRouterQueryParam('order', 'asc');
    const sortField = getRouterQueryParam('orderBy', 'rank');
    const isAsc = sortDir === 'asc';
    const isDesc = sortDir === 'desc';

    const filterHeaders = [
      { name: 'Name', headSortField: 'name', class: classes.sortName },
      { name: 'Rank', headSortField: 'rank', class: classes.sortRank },
      { name: 'Studio+', headSortField: 'studio', class: classes.sortStudio },
      {
        name: 'Auto-Assignments',
        headSortField: 'rules',
        class: classes.sortRules,
      },
      {
        name: 'Workflows',
        headSortField: 'workflows',
        class: classes.sortWorkflows,
      },
    ];

    const sort = newSortField => {
      const newSortDir =
        newSortField === sortField && sortDir === 'asc' ? 'desc' : 'asc';
      amendRouterQuery({ orderBy: newSortField, order: newSortDir });
    };

    const upArrowClass = sort =>
      classnames(classes.sortIcons, classes.greyIcon, {
        [classes.blueIcon]: sort && isAsc,
      });

    const downArrowClass = sort =>
      classnames(classes.sortIcons, classes.greyIcon, {
        [classes.blueIcon]: sort && isDesc,
      });

    return (
      <div className={classes.header}>
        {filterHeaders.map(({ name, headSortField, class: headerClass }) => (
          <div className={headerClass} onClick={() => sort(headSortField)}>
            {name}
            <div className={classes.iconsWrapper}>
              {
                <ArrowDropUp
                  className={upArrowClass(headSortField === sortField)}
                />
              }
              {
                <ArrowDropDown
                  className={downArrowClass(headSortField === sortField)}
                />
              }
            </div>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const {
      projectTemplates: { data, status },
      routerQuery = {},
      projectCountryId,
    } = this.props;
    const { page: routerQueryPageParam = '' } = routerQuery || {};

    if (status === 'failed') return <FailureAlert queryName="Templates" />;
    if (!data.items && status === 'loading') return <Loader />;

    const {
      items: { total_items: totalItems },
    } = data;
    const templateList = this.getTransformedProjectTemplates();

    const {
      approvers,
      verifiers,
      isDeleting,
      isSaving,
      isUpdatingRank,
      errors,
    } = this.state;

    // There are cross overs between approvers and verifiers
    // This makes sure we only pass down one of each user
    // Also it allows us to throw the role in there to filter out users based on tags
    const userObject = {};
    [...approvers].forEach(user => {
      const existingUser = userObject[user.id] || {};
      const { roles = [] } = existingUser;
      userObject[user.id] = {
        ...userObject[user.id],
        id: `${user.id}`,
        fullName: `${user.first_name} ${user.last_name}`,
        roles: [...roles, 'approver'],
      };
    });
    [...verifiers].forEach(user => {
      const existingUser = userObject[user.id] || {};
      const { roles = [] } = existingUser;
      userObject[user.id] = {
        ...userObject[user.id],
        id: `${user.id}`,
        fullName: `${user.first_name} ${user.last_name}`,
        roles: [...roles, 'verifier'],
      };
    });
    const userArray = Object.keys(userObject).map(user => userObject[user]);

    return (
      <div>
        {this.renderHeader()}
        <ProjectTemplates
          page={routerQueryPageParam}
          error={errors}
          users={userArray}
          documents={templateList}
          templateLoadingStatus={status}
          handleUpdate={this.handleUpdate}
          handleDelete={this.handleDelete}
          totalItems={totalItems}
          isDeleting={isDeleting}
          isSaving={isSaving}
          isUpdatingRank={isUpdatingRank}
          projectCountryId={projectCountryId}
        />
      </div>
    );
  }
}

export default withApi(withStyles(styles)(DocumentContainerWrapper));
