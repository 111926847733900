import React from 'react';
import {
  Grid,
  Paper,
  RadioGroup,
  Typography,
  Radio,
  FormControlLabel,
} from '@mui/material';

import useCities from 'common/hooks/useCities';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';
import StudioPlusFolderSelector from 'admin/components/StudioPlusFolderSelector';
import SendOfferDefault from 'common/components/SendOfferDefault';

import LabeledCheckbox from './LabeledCheckbox';
import LabeledSelect from './LabeledSelect';
import LabeledTextArea from './LabeledTextArea';
import LabeledTextField from './LabeledTextField';

import hiringStatusOptions from './hiringStatusOptions';
import useProjectCountry from 'common/components/SpecifiedCountryOnly/useProjectCountry';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import { withStyles } from '@mui/styles';

const ERR_STUDIO_DIR = 'Studio+ directory must be selected.';

const CustomRadio = withStyles({
  root: {
    '&$checked': {
      color: '#0000FF',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const styles = {
  root: {
    marginLeft: '23px',
  },
};

const General = props => {
  const { project, states, countryId, onChange, errors, classes } = props;
  const {
    allowHirersToSendOffers,
    customEmailText,
    employeeType,
    hasHiringStatus,
    sendFinalDocsToStudioPlus,
    sendMycncW4ToStudioPlus,
    sendMycncW9ToStudioPlus,
    sendDataReportToStudioPlus,
    sendProvisionalDealMemo,
    sendDealMemoAfter = 'after_first_level_approvals',
    clientProductionNumber = '',
    trackOffsiteWorkers = false,
    studioPlusDirectoryId,
    studioPlusW9DirectoryId,
    studioPlusMycncW4DirectoryId,
    studioPlusDataReportDirectoryId,
    templatesAcknowledgeNotes,
    templatesAcknowledgeTitle,
    offerDefaults,
    id,
  } = project || {};

  const { data: countryData } = useProjectCountry({ projectId: id });

  const {
    allowEndDate,
    backdateLimit,
    hiringStatus,
    hireState,
    hireCity,
    workState,
    workCity,
    sendDateConfiguration,
  } = offerDefaults || {};
  const { id: hireStateId } = hireState || {};
  const { id: hireCityId } = hireCity || {};
  const { id: workStateId } = workState || {};
  const { id: workCityId } = workCity || {};
  const {
    studio_plus_directory: errStudioPlusDirectory,
    studio_plus_w9_directory: errStudioPlusW9Directory,
    studio_plus_mycnc_w4_directory: errStudioPlusW4Directory,
    studio_plus_data_report_directory: errStudioPlusDataReportDirectory,
    offer_defaults: errOfferDefaults,
  } = errors || {};
  const { backdate_limit: errBackdateLimit } = errOfferDefaults || {};

  const setProjectData = (key, newData) => onChange(key, newData);

  const { data: hireCities, loading: hireCityLoading } = useCities({
    countryId,
    stateId: hireStateId,
  });
  const { data: workCities, loading: workCityLoading } = useCities({
    countryId,
    stateId: workStateId,
  });
  const featureFlags = useFeatureFlags();
  const isStatew4FlagActive = featureFlags.includes('StateW4');
  const isTrackOffsiteWorkersFlagActive = featureFlags.includes(
    'TrackOffsiteWorkers',
  );
  const isSendDealMemoAfterFlagActive = featureFlags.includes(
    'ProvisionalDMAfterEmployeeAccept',
  );
  const showHiringStatus = !hasHiringStatus && countryData?.code === 'US';

  const offerDefaultsChange = newProps => {
    setProjectData('offerDefaults', {
      ...project?.offerDefaults,
      ...newProps,
    });
  };

  const onProvisionalConditionChange = event => {
    const val = event.target.value;
    setProjectData('sendDealMemoAfter', val);
    setProjectData('sendProvisionalDealMemo', true);
  };

  const handleProvisionalDealMemo = val => {
    setProjectData('sendProvisionalDealMemo', val);
    setProjectData('sendDealMemoAfter', 'after_first_level_approvals');
  };

  const selectOptions = data =>
    (data || []).map(({ id, name }) => ({ value: id, label: name }));

  const stateOptions = selectOptions(states);
  const hireCityOptions = selectOptions(hireCities);
  const workCityOptions = selectOptions(workCities);
  const geoChange = newProps => {
    const data = {};
    Object.keys(newProps).forEach(key => {
      data[key] = {};
      Object.keys(newProps[key] || {}).forEach(id => {
        data[key] = { id: parseInt(id), name: newProps[key][id] };
      });
      if (Object.keys(data[key]).length === 0) data[key] = null;
    });
    offerDefaultsChange(data);
  };

  const sendMycncW9ToStudioPlusError =
    sendMycncW9ToStudioPlus && errStudioPlusW9Directory ? ERR_STUDIO_DIR : null;
  const sendMycncW4ToStudioPlusError =
    sendMycncW4ToStudioPlus && errStudioPlusW4Directory ? ERR_STUDIO_DIR : null;
  const sendDataReportToStudioPlusError =
    sendDataReportToStudioPlus && errStudioPlusDataReportDirectory
      ? ERR_STUDIO_DIR
      : null;

  return (
    <BoxItem divider>
      <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
        <span data-test-id="AdditionalDetails-title">
          Manage additional project details
        </span>
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper elevation={0}>
            {showHiringStatus && (
              <LabeledSelect
                label="ACA Hiring Status"
                testId="AdditionalDetails-hiring_status"
                options={hiringStatusOptions(employeeType)}
                value={hiringStatus}
                onChange={val => offerDefaultsChange({ hiringStatus: val })}
                disabled={!!employeeType}
              />
            )}
            <LabeledCheckbox
              name="allow_end_date"
              label="Allow Anticipated End Date"
              checked={allowEndDate || null}
              onChange={val => offerDefaultsChange({ allowEndDate: val })}
            />
            <LabeledCheckbox
              name="allow_hirers_to_send_offers"
              label="Allow Hiring Managers To Send Offers"
              checked={allowHirersToSendOffers || null}
              onChange={val => setProjectData('allowHirersToSendOffers', val)}
            />
            <LabeledCheckbox
              name="send_final_docs_to_studio_plus"
              label="Send Final Offer Documents to Studio+"
              checked={sendFinalDocsToStudioPlus || null}
              onChange={val => setProjectData('sendFinalDocsToStudioPlus', val)}
              error={
                sendFinalDocsToStudioPlus && errStudioPlusDirectory
                  ? ERR_STUDIO_DIR
                  : null
              }
            />
            {sendFinalDocsToStudioPlus && (
              <StudioPlusFolderSelector
                directoryId={studioPlusDirectoryId}
                onChangeDirectoryId={id =>
                  setProjectData('studioPlusDirectoryId', id)
                }
              />
            )}
            <UsaOnly>
              <>
                <LabeledCheckbox
                  name="send_mycnc_w9_to_studio_plus"
                  label="Send Loan-out W9 from MyC&amp;C to Studio+"
                  checked={sendMycncW9ToStudioPlus || null}
                  onChange={val =>
                    setProjectData('sendMycncW9ToStudioPlus', val)
                  }
                  error={sendMycncW9ToStudioPlusError}
                />
                {sendMycncW9ToStudioPlus && (
                  <StudioPlusFolderSelector
                    directoryId={studioPlusW9DirectoryId}
                    onChangeDirectoryId={id =>
                      setProjectData('studioPlusW9DirectoryId', id)
                    }
                  />
                )}
                {isStatew4FlagActive && (
                  <LabeledCheckbox
                    name="send_mycnc_w4_to_studio_plus"
                    label="Send W4 from MyC&amp;C to Studio+"
                    checked={sendMycncW4ToStudioPlus || null}
                    onChange={val =>
                      setProjectData('sendMycncW4ToStudioPlus', val)
                    }
                    error={sendMycncW4ToStudioPlusError}
                  />
                )}
                {isStatew4FlagActive && sendMycncW4ToStudioPlus && (
                  <StudioPlusFolderSelector
                    directoryId={studioPlusMycncW4DirectoryId}
                    onChangeDirectoryId={id =>
                      setProjectData('studioPlusMycncW4DirectoryId', id)
                    }
                  />
                )}
              </>
            </UsaOnly>
            <LabeledCheckbox
              name="send_data_report_to_studio_plus"
              label="Send Project Data Export to Studio+"
              checked={sendDataReportToStudioPlus || null}
              onChange={val =>
                setProjectData('sendDataReportToStudioPlus', val)
              }
              error={sendDataReportToStudioPlusError}
            />
            {sendDataReportToStudioPlus && (
              <StudioPlusFolderSelector
                directoryId={studioPlusDataReportDirectoryId}
                onChangeDirectoryId={id =>
                  setProjectData('studioPlusDataReportDirectoryId', id)
                }
              />
            )}
            <LabeledCheckbox
              name="send_provisional_deal_memo"
              label="Send Provisional Deal Memo to Payroll System"
              checked={sendProvisionalDealMemo || null}
              onChange={val => handleProvisionalDealMemo(val)}
            />
            {sendProvisionalDealMemo && isSendDealMemoAfterFlagActive && (
              <div>
                <RadioGroup
                  className={classes.root}
                  value={sendDealMemoAfter}
                  onChange={e => onProvisionalConditionChange(e)}
                  row
                >
                  <FormControlLabel
                    control={<CustomRadio />}
                    label={<label>After Employee Accepts Offer</label>}
                    value={'after_employee_accepts_offer'}
                  />
                  <FormControlLabel
                    control={<CustomRadio />}
                    label={<label>After 1st Level Approvals</label>}
                    value={'after_first_level_approvals'}
                  />
                </RadioGroup>
              </div>
            )}
            {isTrackOffsiteWorkersFlagActive && (
              <UsaOnly>
                <LabeledCheckbox
                  name="Track Offsite Workers"
                  label="Track Offsite Workers"
                  checked={trackOffsiteWorkers || null}
                  onChange={val => setProjectData('trackOffsiteWorkers', val)}
                />
              </UsaOnly>
            )}
            <LabeledTextField
              label="Start backdate limit (days)"
              value={backdateLimit}
              onChange={val => {
                offerDefaultsChange({
                  backdateLimit: parseInt(val) ? parseInt(val) : null,
                });
              }}
              error={errBackdateLimit ? 'Value is invalid or missing' : null}
              required
            />
            <LabeledTextField
              label="Client Production Number"
              value={clientProductionNumber}
              onChange={val => setProjectData('clientProductionNumber', val)}
            />
            <LabeledTextField
              label="Grouped acknowledgment dialog title"
              value={templatesAcknowledgeTitle}
              onChange={val => setProjectData('templatesAcknowledgeTitle', val)}
            />
            <LabeledTextArea
              label="Grouped acknowledgment dialog body"
              value={templatesAcknowledgeNotes}
              onChange={val => setProjectData('templatesAcknowledgeNotes', val)}
              rows={3}
            />
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper elevation={0}>
            <Typography
              variant="h6"
              gutterBottom
              style={{ fontWeight: 'bold' }}
            >
              Custom Email Text
            </Typography>
            <LabeledTextArea
              label={false}
              value={customEmailText}
              onChange={val => setProjectData('customEmailText', val)}
              rows={15}
            />
            <Grid divider container spacing={1}>
              <Grid item xs={12}>
                <UsaOnly>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ fontWeight: 'bold' }}
                  >
                    Default Hire State and City
                  </Typography>
                </UsaOnly>
                <CanadaOnly>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ fontWeight: 'bold' }}
                  >
                    Default Hire Province
                  </Typography>
                </CanadaOnly>
              </Grid>
              <Grid item xs={12}>
                <UsaOnly>
                  <LabeledSelect
                    label="Hire State"
                    options={stateOptions}
                    value={hireStateId}
                    onChange={(val, name) => {
                      geoChange({
                        hireState: val ? { [val]: name } : null,
                        hireCity: null,
                      });
                    }}
                    clearable
                  />
                </UsaOnly>
                <CanadaOnly>
                  <LabeledSelect
                    label="Hire Province"
                    options={stateOptions}
                    value={hireStateId}
                    onChange={(val, name) => {
                      geoChange({
                        hireState: val ? { [val]: name } : null,
                        hireCity: null,
                      });
                    }}
                    clearable
                  />
                </CanadaOnly>
              </Grid>
              <Grid item xs={12}>
                <UsaOnly>
                  <LabeledSelect
                    label="Hire City"
                    options={hireCityOptions}
                    value={hireCityId}
                    onChange={(val, name) =>
                      geoChange({ hireCity: val ? { [val]: name } : null })
                    }
                    loading={hireCityLoading}
                    clearable
                  />
                </UsaOnly>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <UsaOnly>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ fontWeight: 'bold' }}
                  >
                    Default Work State and City
                  </Typography>
                </UsaOnly>
                <CanadaOnly>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ fontWeight: 'bold' }}
                  >
                    Default Work Province
                  </Typography>
                </CanadaOnly>
              </Grid>
              <Grid item xs={12}>
                <UsaOnly>
                  <LabeledSelect
                    label="Work State"
                    options={stateOptions}
                    value={workStateId}
                    onChange={(val, name) => {
                      geoChange({
                        workState: val ? { [val]: name } : null,
                        workCity: null,
                      });
                    }}
                    clearable
                  />
                </UsaOnly>
                <CanadaOnly>
                  <LabeledSelect
                    label="Work Province"
                    options={stateOptions}
                    value={workStateId}
                    onChange={(val, name) => {
                      geoChange({
                        workState: val ? { [val]: name } : null,
                        workCity: null,
                      });
                    }}
                    clearable
                  />
                </CanadaOnly>
              </Grid>
              <Grid item xs={12}>
                <UsaOnly>
                  <LabeledSelect
                    label="Work City"
                    options={workCityOptions}
                    value={workCityId}
                    onChange={(val, name) =>
                      geoChange({ workCity: val ? { [val]: name } : null })
                    }
                    loading={workCityLoading}
                    clearable
                  />
                </UsaOnly>
              </Grid>
            </Grid>
          </Paper>
          <SendOfferDefault
            value={sendDateConfiguration}
            onChange={value => {
              const val = value === 'null' ? null : value;
              offerDefaultsChange({ sendDateConfiguration: val });
            }}
          />
        </Grid>
      </Grid>
    </BoxItem>
  );
};

export default withStyles(styles)(General);
