import React from 'react';

// HoC
import { withStyles } from '@mui/styles';

// Commponents
import {
  Menu,
  MenuItem,
  FormControlLabel,
  Checkbox,
  MenuList,
} from '@mui/material';
// Icons

const styles = theme => ({
  root: {
    width: '180px',
  },
  menuItem: {
    backgroundColor: 'transparent !important',
    padding: '6px 25px',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  label: {
    fontSize: '1rem',
    fontWeight: 500,
  },
});

const CrewFilterMenu = props => {
  const {
    classes,
    onChange: upstreamOnChange,
    filters = {},
    open,
    anchor,
    onClose,
  } = props;
  const menuItems = [
    {
      label: 'Without Offers',
      selected: filters.withoutOffers,
      onChange: () =>
        upstreamOnChange({ withoutOffers: !filters.withoutOffers }),
    },
    {
      label: 'With Offers',
      selected: filters.withOffers,
      onChange: () => upstreamOnChange({ withOffers: !filters.withOffers }),
    },
  ];
  return (
    <Menu
      id="role-menu-add-approver"
      anchorEl={anchor ? anchor : undefined}
      open={open}
      onClose={onClose}
      data-test-id="CrewFilterMenu-root"
      classes={{ paper: classes.root }}
    >
      <MenuList sx={{ padding: '0px' }}>
        {menuItems.map(({ label, selected, onChange }) => (
          <MenuItem
            value={label}
            selected={selected}
            data-test-id={`CrewFilterMenu-menuItem-${label}`}
            key={label}
            classes={{ root: classes.menuItem }}
            divider={true}
          >
            <FormControlLabel
              control={
                <Checkbox onChange={onChange} checked={selected} id={label} />
              }
              label={label}
              classes={{ label: classes.label }}
            />
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default withStyles(styles)(CrewFilterMenu);
