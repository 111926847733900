import React from 'react';

// HoC
import { withStyles } from '@mui/styles';
import { styled } from '@mui/system';

// Components
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';

import Loader from 'common/components/Loader';

// Icons
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/CheckCircle';

const styles = theme => ({
  root: {},
  checkIcon: {
    color: theme.palette.primary.main,
  },
  input: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: '5px 10px',
    borderRadius: '8px',
    '&:hover, &:focus': {
      borderBottom: `1px solid ${theme.palette.grey[400]} !important`,
    },
    '&:before, &:after': {
      borderBottom: '1px solid transparent !important',
    },
  },
});

const StyledFormControl = styled(FormControl)({
  margin: '25px 0px 14px',
  gridRow: 4,
  gridColumn: '1 / -1',
});

const CrewSearch = ({ onChange, isLoading, value = '', classes = {} }) => {
  const endAdornment = () => {
    if (value === '') return null;
    if (isLoading)
      return (
        <InputAdornment position="end">
          <Loader size={15} />
        </InputAdornment>
      );
    return (
      <InputAdornment position="end">
        <CheckIcon className={classes.checkIcon} />
      </InputAdornment>
    );
  };
  return (
    <StyledFormControl
      fullWidth
      className={classes.root}
      data-test-id="CrewSearch-root"
    >
      <Input
        id="input-with-icon-textfield"
        value={value}
        onChange={onChange}
        autoComplete="off"
        classes={{ root: classes.input }}
        inputProps={{
          'data-test-id': 'CrewSearch-input',
        }}
        placeholder="Search for crew member"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        endAdornment={endAdornment()}
      />
    </StyledFormControl>
  );
};

export default withStyles(styles)(CrewSearch);
