import React, { useState } from 'react';
import useMe from 'common/hooks/useMe';

// HoC

import { withStyles } from '@mui/styles';
import { styled } from '@mui/system';

// Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Loader from 'common/components/Loader';

// Utilities
import history from 'common/constants/config/history';
import validateName from 'common/utilities/validateName';
import validateEmail from 'common/utilities/validateEmail';
import classNames from 'classnames';

// Colors
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';

import useAddCrewMember from 'studio/hooks/useAddCrewMember';
import ErrorIndicator from 'studio/oldJavascripts/components/Pages/Project/RejectI9/ErrorIndicator';

const styles = {
  root: {
    display: 'grid',
    width: '100%',
    height: 'calc(100% - 120px)',
    gridTemplateColumns: '100%',
    gridTemplateRows: '25px calc(100% - 25px)',
    // placeItems: 'center',
    justifyItems: 'center',
    gridTemplateAreas: `
    "....."
    "paper"`,
  },
  paper: {
    gridArea: 'paper',
    display: 'grid',
    height: '550px',
    width: '460px',
    gridTemplateColumns: '15% 35% 35% 15%',
    gridTemplateRows: '5% 15% 50% 20% 10%',
    gridTemplateAreas: `
    ". ...... ...... ."
    ". header header ."
    ". form   form   ."
    ". cancel submit ."
    "error error error error"`,
    minHeight: 510,
  },
  header: {
    gridArea: 'header',
    placeSelf: 'center',
  },
  form: {
    gridArea: 'form',
    display: 'grid',
  },
  submitWrapper: {
    gridArea: 'submit',
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
    gridTemplateAreas: `
    "submitButton"`,
    height: '48px',
    width: '141px',
    placeSelf: 'center',
  },
  underline: {
    '&:before': {
      borderBottomColor: '#2E1EFF',
    },
    '&:after': {
      borderBottomColor: '#0000FF',
    },
  },
  errorUnderline: {
    '&:before': {
      borderBottomColor: '#f44336',
    },
    '&:after': {
      borderBottomColor: '#f44336',
    },
  },
  errorLabel: {
    color: '#f44336',
  },
  submitButton: {
    gridArea: 'submitButton',
  },
  submitSuccess: {
    color: 'white',
    backgroundColor: '#0000FF',
    '&:hover': {
      backgroundColor: '#1A55FD',
    },
  },
  submitFailure: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  submitNormalState: {
    color: 'white',
    backgroundColor: '#0000FF',
    '&:hover': {
      backgroundColor: '#1A55FD',
    },
  },
  submitDisabled: {
    backgroundColor: '#9A9FAF !important',
    color: 'white !important',
  },
  submitProgress: {
    color: green[500],
    gridArea: 'submitButton',
  },
  cancelBtn: {
    backgroundColor: '#e0e0e0 !important',
    color: '#000 !important',
    '&:hover': {
      backgroundColor: '#d5d5d5 !important',
    },
  },
  error: {
    gridArea: 'error',
    height: '48px',
    placeSelf: 'start',
    color: '#000000',
  },
};

const CancelButton = styled(Button)({
  gridArea: 'cancel',
  height: '48px',
  width: '141px',
  placeSelf: 'center',
  color: '#000000',
});

const Error = styled('div')({
  gridArea: 'error',
  height: '48px',
  placeSelf: 'start',
  color: '#000000',
});

const AddCrewMember = props => {
  const [addCrewMember] = useAddCrewMember();
  const me = useMe();
  const managerProfileId = me?.data?.id || '';
  const projectId = props?.match?.params?.projectId || props?.projectId;
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    emailValid: null,
    submitInProgress: false,
    submitSuccess: false,
    submitFailure: false,
    error: null,
  });
  const errorMessage = state?.error;
  const { onClose, modal } = props;

  const handleEmailKeyPress = e => {
    setState({
      ...state,
      submitInProgress: false,
      submitSuccess: false,
      submitFailure: false,
    });
    const { key } = e || {};
    if (key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      handleSubmit();
    }
  };
  const handleChange = key => value => setState({ ...state, [key]: value });

  const emailOnChange = e => {
    const { target: { value: email } = {} } = e;
    // Only check to correct the validation while typing
    // dont try to create a validation error while typing
    setState({ ...state, email, emailValid: validateEmail(email) });
  };

  const checkEmailValidation = () => {
    const { email } = state;
    setState({ ...state, emailValid: validateEmail(email) });
  };

  const handleSubmit = () => {
    const { firstName, middleName, lastName, email, submitSuccess } = state;
    const variables = {
      firstName,
      middleName,
      lastName,
      email,
      managerProfileId,
      projectId,
    };

    // Dont send off the request if the email is not valid
    if (!validateEmail(email) || submitSuccess) return;
    setState({
      ...state,
      submitInProgress: true,
      submitSuccess: false,
      submitFailure: false,
    });

    addCrewMember({ variables })
      .then(({ data }) => {
        const { addCrewMember } = data;
        if (addCrewMember) {
          setState({ ...state, submitSuccess: true, submitInProgress: false });
          if (modal) {
            const addedData = {
              email,
            };
            onClose(addedData);
            return;
          }
          setTimeout(goBack, 400);
        }
      })
      .catch(err => {
        const errorMessage = err?.message.replace('GraphQL error: ', '');
        const { graphQLErrors = [] } = err || {};
        const { extensions: { data = {} } = {} } = graphQLErrors[0] || {};
        // In case of email error, show the email error
        // Otherwise show the general error message
        setState({
          ...state,
          submitInProgress: false,
          submitFailure: true,
          error: data?.email ? data?.email : errorMessage,
        });
      });
  };
  const goBack = () => {
    setState({
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      emailValid: null,
      submitInProgress: false,
      submitSuccess: false,
      submitFailure: false,
    });
    history.push(`/projects/${projectId}/crew`);
  };

  const { crew: crewMutator = {}, classes = {} } = props;
  const { errors = {} } = crewMutator || {};
  const {
    first_name: firstNameError,
    middle_name: middleNameError,
    last_name: lastNameError,
    email: emailError,
  } = errors || {};
  const {
    firstName,
    lastName,
    middleName,
    email,
    emailValid,
    submitInProgress,
    submitSuccess,
    submitFailure,
  } = state;

  const submitEnabled = () =>
    !!firstName && !!lastName && emailValid && !!email;

  const submitButtonClasses = classNames(
    {
      [classes.submitInProgress]: submitInProgress,
      [classes.submitSuccess]: submitSuccess,
      [classes.submitFailure]: submitFailure,
      [classes.submitNormalState]:
        !submitInProgress && !submitSuccess && !submitFailure,
    },
    classes.submitButton,
  );
  const isError =
    submitFailure &&
    (firstNameError || lastNameError || middleNameError || emailError);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography
          className={classes.header}
          variant="h4"
          data-test-id="AddCrewMember-title"
        >
          Add Crew Member
        </Typography>
        <form className={classes.form} noValidate autoComplete="off">
          <TextField
            id="first-name"
            label="First Name"
            error={submitFailure && firstNameError}
            className={classes.textField}
            value={firstName}
            onChange={({ target: { value } = {} }) =>
              handleChange('firstName')(validateName(value))
            }
            margin="normal"
            required
            InputProps={{
              className: firstNameError
                ? classes.errorUnderline
                : classes.underline,
            }}
            InputLabelProps={{
              className: firstNameError ? classes.errorLabel : '',
            }}
            data-test-id="AddCrewMember-firstName"
            variant="standard"
          />
          <TextField
            id="middle-name"
            label="Middle Name"
            error={submitFailure && middleNameError}
            className={classes.textField}
            value={middleName}
            onChange={({ target: { value } = {} }) =>
              handleChange('middleName')(validateName(value))
            }
            margin="normal"
            InputProps={{
              className: middleNameError
                ? classes.errorUnderline
                : classes.underline,
            }}
            InputLabelProps={{
              className: middleNameError ? classes.errorLabel : '',
            }}
            data-test-id="AddCrewMember-middleName"
            variant="standard"
          />
          <TextField
            id="last-name"
            label="Last Name"
            error={submitFailure && lastNameError}
            className={classes.textField}
            value={lastName}
            onChange={({ target: { value } = {} }) =>
              handleChange('lastName')(validateName(value))
            }
            margin="normal"
            required
            InputProps={{
              className: lastNameError
                ? classes.errorUnderline
                : classes.underline,
            }}
            InputLabelProps={{
              className: lastNameError ? classes.errorLabel : '',
            }}
            data-test-id="AddCrewMember-lastName"
            variant="standard"
          />
          <TextField
            value={email}
            error={submitFailure && (emailValid === false || emailError)}
            onChange={emailOnChange}
            onBlur={checkEmailValidation}
            onKeyPress={handleEmailKeyPress}
            className={classNames(classes.textField, classes.email)}
            data-test-id="AddCrewMember-email"
            label="Personal Email"
            InputProps={{
              className:
                emailValid === false
                  ? classes.errorUnderline
                  : classes.underline,
            }}
            InputLabelProps={{
              className: emailValid === false ? classes.errorLabel : '',
            }}
            margin="normal"
            required
            variant="standard"
          />
          {isError && (
            <Typography
              color="error"
              variant="caption"
              className={classes.errorLabel}
            >
              {firstNameError || lastNameError || middleNameError || emailError}
            </Typography>
          )}
        </form>
        <div className={classes.submitWrapper}>
          <Button
            className={submitButtonClasses}
            disabled={!submitEnabled() || submitInProgress}
            onClick={handleSubmit}
            variant="contained"
            classes={{
              disabled: classes.submitDisabled,
            }}
            data-test-id="AddCrewMember-submit"
          >
            Submit
          </Button>
          {submitInProgress && (
            <Loader size={24} className={classes.submitProgress} />
          )}
        </div>
        <CancelButton
          variant="contained"
          onClick={modal ? onClose : goBack}
          className={classes.cancelBtn}
        >
          Cancel
        </CancelButton>
        {errorMessage && (
          <Error>
            <ErrorIndicator message={errorMessage} />
          </Error>
        )}
      </Paper>
    </div>
  );
};

export default withStyles(styles)(AddCrewMember);
