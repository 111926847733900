import React, { PureComponent } from 'react';

import { withStyles } from '@mui/styles';
import { Accordion, AccordionSummary } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import DocumentHeader from './DocumentHeader';
import ProjectTemplateBody from './ProjectTemplateBody';
import DocumentFooter from './DocumentFooter';
import withRouteHelpers from 'common/hoc/withRouteHelpers';

const styles = theme => ({
  root: {
    margin: theme.spacing(1),
  },
  summaryContent: {
    margin: 0,
  },
  containedButton: {
    background: '#0000FF',
    '&:hover': {
      background: '#1A55FD',
    },
    '&:active': {
      background: '#0000B3',
    },
  },
  badge: {
    color: '#fff !important',
  },
});

class ProjectTemplate extends PureComponent {
  state = {
    gqlCallback: null,
    autoAssignCount: null,
    studioCount: null,
    count: {
      autoAssignmentRulesCount: this.props.autoAssignmentRulesCount,
      studioPlusDirectoriesCount: this.props.studioPlusDirectoriesCount,
    },
  };

  // update the count state when props change
  componentDidUpdate(prevProps) {
    if (
      prevProps.autoAssignmentRulesCount !==
        this.props.autoAssignmentRulesCount ||
      prevProps.studioPlusDirectoriesCount !==
        this.props.studioPlusDirectoriesCount
    ) {
      this.setState({
        count: {
          autoAssignmentRulesCount: this.props.autoAssignmentRulesCount,
          studioPlusDirectoriesCount: this.props.studioPlusDirectoriesCount,
        },
      });
    }
  }

  submit = async (...args) => {
    const { handleSubmit } = this.props;
    const { gqlCallback } = this.state;
    const submitComplete = await handleSubmit(...args);
    // If the REST request is failed, we don't want to call the GraphQL callback
    if (submitComplete) {
      if (gqlCallback) {
        gqlCallback().then(res => {
          const count = this.state.count;
          const sCount =
            res?.data?.projectTemplate?.studioPlusDirectories?.length;
          this.setState({
            count: {
              ...count,
              autoAssignmentRulesCount: this.props?.auto_assign_rules?.length,
              studioPlusDirectoriesCount: sCount,
            },
          });
        });
      }
    }
  };

  render() {
    const {
      classes,
      id,
      acknowledge_notes: acknowledgeNotes,
      acknowledge_options: acknowledgeOptions,
      acknowledge_title: acknowledgeTitle,
      acknowledge_type: acknowledgeType,
      deleteDoc,
      isDeleting,
      isUpdatingRank,
      name,
      error = {},
      rank,
      supportDocument,
      updateDoc,
      users,
      updateRank,
      workflow = {},
      isSaving = false,
      saveSuccess = false,
      active = false,
      routerHash,
      amendRouterHash,
      projectCountryId,
      workflowsCount,
    } = this.props;
    const { count } = this.state;
    const isExpanded = routerHash === id;
    // Until this whole page is converted to GraphQL we have to submit two requests

    return (
      <Accordion
        expanded={isExpanded}
        classes={{ root: classes.root }}
        onChange={(__, newIsExpanded) => {
          if (newIsExpanded !== isExpanded) {
            const newHash = newIsExpanded ? id : null;
            amendRouterHash(newHash);
          }
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon data-test-id={`Document-expand-${id}`} />}
          classes={{
            content: classes.summaryContent,
          }}
        >
          <DocumentHeader
            deleteDoc={deleteDoc}
            name={name}
            id={id}
            isActive={active}
            isDeleting={isDeleting}
            isUpdatingRank={isUpdatingRank}
            rank={rank}
            updateDoc={updateDoc}
            updateRank={updateRank}
            counts={count}
            workflowsCount={workflowsCount}
          />
        </AccordionSummary>
        {isExpanded && (
          <React.Fragment>
            <ProjectTemplateBody
              acknowledgeNotes={acknowledgeNotes}
              acknowledgeOptions={acknowledgeOptions}
              acknowledgeTitle={acknowledgeTitle}
              acknowledgeType={acknowledgeType}
              id={id}
              isExpanded={isExpanded}
              supportDocument={supportDocument}
              workflow={workflow}
              updateDoc={updateDoc}
              users={users}
              onSetGqlCallback={gqlCallback => this.setState({ gqlCallback })}
              workflowsCount={workflowsCount}
              projectCountryId={projectCountryId}
              classes={classes}
            />
            <DocumentFooter
              error={error.message}
              handleSubmit={this.submit}
              id={id}
              isSaving={isSaving}
              saveSuccess={saveSuccess}
              supportDocument={supportDocument}
              updateDoc={updateDoc}
            />
          </React.Fragment>
        )}
      </Accordion>
    );
  }
}

export default withRouteHelpers(withStyles(styles)(ProjectTemplate));
